import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Courses } from "../../particles/DataLists";
import CourseCard from "../../molecules/CourseCard";

const CourseShowcase = () => {
  const navigate = useNavigate();
  const location = useLocation();
  function onClickSelfStudy(courseId) {
    window.scrollTo(0, 0);
    const currentPath = location.pathname;
    setTimeout(() => {
        navigate(`${currentPath}/${courseId}`); // Then navigate to the specific course
    }, 0);
}
  return (
    <section className="py-12 bg-gradient-to-b from-gray-50 to-white sm:py-16 lg:py-20 mt-4">
      <div className="px-4 mx-auto my-8 max-w-[90vw] sm:px-6 lg:px-8">
        <h1 className="text-2xl font-semibold mb-2">Courses</h1>
        <div className="flex gap-4 flex-wrap">
          {Courses.map((course, index) => (
            <CourseCard
            onClickSelfStudy={onClickSelfStudy}
              key={index}
              course={course}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default CourseShowcase;
