import React from "react";
import "./Marqee.css";

const Marquee = () => {
  // List of logos for different categories with titles
  const data = [
    {
      title: "Our Academic Connections",
      logos: [
        {
          url: "https://upload.wikimedia.org/wikipedia/en/thumb/1/1d/Indian_Institute_of_Technology_Bombay_Logo.svg/300px-Indian_Institute_of_Technology_Bombay_Logo.svg.png",
          name: "IIT Bombay",
        },
        {
          url: "https://upload.wikimedia.org/wikipedia/en/thumb/d/d3/BITS_Pilani-Logo.svg/400px-BITS_Pilani-Logo.svg.png",
          name: "BITS Pilani",
        },
        {
          url: "https://images.shiksha.com/mediadata/images/1571907236phpTCgKIg_m.jpg",
          name: "NIT SURATKAL",
        },
        {
          url: "https://images.shiksha.com/mediadata/images/1563770374php4OuTRZ.jpg",
          name: "NIT HAMIRPUR",
        },
        {
          url: "https://upload.wikimedia.org/wikipedia/en/thumb/c/c2/Yeshiva_University.svg/300px-Yeshiva_University.svg.png",
          name: "Yeshiva University, NYC US",
        },
        {
          url: "https://images.shiksha.com/mediadata/images/1687868166php8yeOso.jpeg",
          name: "IIT DELHI",
        },
        {
          url: "https://images.shiksha.com/mediadata/images/1633937861phpHc5RFA.jpeg",
          name: "IIM AHEMDABAD",
        },
        {
          url: "https://upload.wikimedia.org/wikipedia/en/thumb/c/c5/Vellore_Institute_of_Technology_seal_2017.svg/400px-Vellore_Institute_of_Technology_seal_2017.svg.png",
          name: "VIT",
        },
        {
          url: "https://upload.wikimedia.org/wikipedia/en/thumb/a/a3/IIT_Kanpur_Logo.svg/400px-IIT_Kanpur_Logo.svg.png",
          name: "IIT KANPUR",
        },
        {
          url: "https://images.shiksha.com/mediadata/images/1569304335phpHattQO.jpg",
          name: "SRM",
        },
        {
          url: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3e/IIMBG_LOGO_With_Space.png/440px-IIMBG_LOGO_With_Space.png",
          name: "IIM Bodh Gaya",
        },
        {
          url: "https://upload.wikimedia.org/wikipedia/en/thumb/e/e9/Netaji_Subhas_University_of_Technology.svg/300px-Netaji_Subhas_University_of_Technology.svg.png",
          name: "NSUT Delhi",
        },
      ],
    },
    {
      title: "Prestigious Placements Secured",
      logos: [
        {
          url: "https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg",
          name: "Microsoft, Research Intern",
        },
        {
          url: "https://upload.wikimedia.org/wikipedia/en/thumb/1/1d/Indian_Institute_of_Technology_Bombay_Logo.svg/300px-Indian_Institute_of_Technology_Bombay_Logo.svg.png",
          name: "IIT Bombay, AI Research Lab",
        },
        {
          url: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/Logo-True-Colors-original.png/440px-Logo-True-Colors-original.png",
          name: "Vision AI Team",
        },
        {
          url: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Paytm_Logo_%28standalone%29.svg/300px-Paytm_Logo_%28standalone%29.svg.png",
          name: "GEN AI Team",
        },
        {
          url: "https://upload.wikimedia.org/wikipedia/en/f/f5/Schottenstein_Stores_Corp_Logo.jpg",
          name: "Schottenstein Stores, US",
        },
      ],
    },
  ];

  const MarqueeSection = ({ title, logos, reverse }) => (
    <div className="overflow-hidden py-6 w-full">
      <h2 className="text-center text-3xl font-bold leading-tight text-gray-400 mb-8">
        {title}
      </h2>
      <div className="marquee-container w-[100vw] overflow-y-auto transition-all duration-300 scale-105 pb-4">
        <div
          className={` marquee-content ${
            reverse ? "animate-marquee-reverse" : "animate-marquee"
          } `}
        >
          {[...logos, ...logos].map((logo, index) => (
            <div
              key={index}
              className="w-auto flex flex-col mr-16 max-md:mr-10 max-sm:mr-4 justify-center items-center overflow-hidden bg-white rounded-md cursor-pointer hover:scale-105"
            >
              <img
                src={logo.url}
                alt={`${title} Logo ${index + 1}`}
                className="h-16 w-auto object-contain  max-h-20"
              />
              <p className="min-w-[100px] text-sm font-medium text-gray-700 mt-2 ml-0 text-center">
                {logo.name}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
  return (
    <div className="space-y-8 w-full mt-20">
      {data.map((section, index) => (
        <MarqueeSection
          key={index}
          title={section.title}
          logos={section.logos}
          reverse={index % 2 !== 0}
        />
      ))}
    </div>
  );
};

export default Marquee;
