import React from "react";
import HeadindText from "../pages/Utils/HeadindText";

const trainingHighlights = [
  {
    title: "Challenging Projects",
    description:
      "Our projects are designed to push boundaries, helping interns develop problem-solving skills and confidence in tackling complex challenges.",
    emoji: "🚀",
  },
  {
    title: "Open Source Contributions",
    description:
      "Active contributions to GitHub repositories provide our interns with practical knowledge of collaborative and scalable software development.",
    emoji: "🌟",
  },
  {
    title: "In-Depth Expertise",
    description:
      "We focus on implementation at a production level, ensuring our interns develop a thorough understanding of advanced concepts and accuracy requirements.",
    emoji: "🔬",
  },
  {
    title: "Model Deployment",
    description:
      "Beyond just writing code, we emphasize the deployment of models and algorithms, equipping interns with essential production-ready skills.",
    emoji: "📦",
  },
  {
    title: "Mastery in Specialization",
    description:
      "Our training ensures interns gain unparalleled expertise in their project area, often surpassing the knowledge of interviewers.",
    emoji: "🏆",
  },
];

const SuccessTraining = () => {
  return (
    <div className="py-16 px-4 w-[80vw] max-md:w-[98vw]">
      
      <h3 className="text-3xl font-bold text-center mb-8">
      <HeadindText Heading={` Why Our Students Excel?`} />
      </h3>
      <p className="text-lg text-center text-gray-600 max-w-2xl mx-auto mb-12">
        Our rigorous training programs and real-world implementations empower
        our interns to surpass expectations and impress interviewers.
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {trainingHighlights.map((item, index) => (
          <div
            key={index}
            className="group bg-white shadow-md p-6 rounded-lg border-t-4 border-indigo-500 transition-transform duration-300 ease-out hover:scale-105 hover:shadow-lg hover:bg-gradient-to-b from-indigo-600 to-indigo-700 cursor-pointer"
          >
            <h4 className="text-xl font-semibold  mb-4 flex items-center group-hover:text-gray-50">
              <span className="mr-2">{item.emoji}</span> {item.title}
            </h4>
            <p className="group-hover:text-gray-200">{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SuccessTraining;
