import React, { useState } from "react";
import "../../Projects/AudioGen/MarkdownToSpeech.css";
import ReactPlayer from "react-player";
import { LinkedIn, GitHub, Instagram } from "@mui/icons-material";
import Markdowntoaudio from "../ModelComponent/Markdowntoaudio";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import StarIcon from "@mui/icons-material/Star";
import SchoolIcon from "@mui/icons-material/School";
import BusinessIcon from "@mui/icons-material/Business";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import GradeIcon from "@mui/icons-material/Grade";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import CloudIcon from "@mui/icons-material/Cloud";
import { useNavigate } from "react-router-dom";
import { discordInvite } from "../../../config";
const contentData = {
  MainHeading: "Audio-to-Audio AI Chatbot",
  header: {
    title: "AI-Powered Speech-to-Speech Response Generation",
    description:
      "",
  },
  features: [
    "Instant Text-to-Audio responses with natural, human-like voices.",
    "Flexible voice customization: adjust tone, pitch, speed, and emotions.",
    "Highly precise conversion.",
    "Comprehensive support for diverse languages and regional accents.",
    "Smart Text-to-Text response generation powered by advanced LLM models.",
    "Real-time adaptability for dynamic conversational flows.",
    "Provides state-of-the-art AI solution for seamless audio to audio communication.",
  ],
  additionalInfo: [
    { title: "AI-driven conversation flow for natural interactions." },
    { title: "Real-time, precise speech recognition." },
    { title: "Integrates with popular voice assistants and smart devices." },
  ],
  otherDetails: {
    trial: "Free Trial Available",
    duration: "4 weeks",
    scholarships: "Certification upon completion",
    career: "Career Path: AI Engineer",
  },
  additionalFeatures: {
    resourceSpent: "10,000+ speech interactions processed.",
    timeTaken: "4 months development time",
    pricing: "Free for members & interns, $250 for individual projects",
    rating: "Customer Rating: 4.9/5",
  },
  authorDetails: {
    projectLead: "Shivam Uppal, Baibhav Kundu, Krishna Kaushik",
    position: "AI Interns",
    modelsUsed: "Powered by Google TTS, gTTS, OpenAI Whisper",
    modelHosted: "Hosted on AWS Cloud",
    accuracy: "Accuracy: 85%",
    image: "profileImageUrl", // Replace with actual image URL
    author: "Dr. Anil Kumar",
    linkedin: "https://www.linkedin.com/company/neugence/people", // Replace with actual LinkedIn URL
    github: "https://github.com/neugence/audio_to_audio", // Replace with actual GitHub URL
  },
  scale: {
    ActiveLearners: "15+ active users India, US, Germany",
    Scale: "Enterprise-grade scalability with global reach",
  },
};

const ProjectDemo = (modelId) => {
  const navigate = useNavigate();

  function onClickSelfStudyProject() {
    window.scrollTo(0, 0);
    navigate(`projects/Speech_to_speech_Ai_chatbot`);
  }
  const [activeTab, setActiveTab] = useState("Feature");
  const [hovered, setHovered] = useState(false);
  const [markdownInput, setMarkdownInput] = useState("hello and welcome to acehub website");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [generatedText, setGeneratedText] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);

  const handleInputChange = (e) => {
    setMarkdownInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setGeneratedText(null);
    setAudioUrl(null);

    try {
      const response = await fetch("https://mailchamp.ai:5005/markdown_speak", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ markdown_input: markdownInput }),
      });

      if (!response.ok) {
        throw new Error("Failed to generate speech. Please try again.");
      }

      const data = await response.json();
      setGeneratedText(data.generated_text);
      const audioUrl = `data:audio/wav;base64,${data.audio_base64}`;
      setAudioUrl(audioUrl);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
   
      <div className=" mb-12 mx-auto md:w-[80vw] max-md:w-[100vw] p-4 max-md:p-1 md:rounded-2xl bg-gradient-to-r from-gray-700 to-gray-800 shadow-lg ">
        <div className="">
          <div className="rounded-2xl  text-white p-4 px-4 shadow-2xl flex justify-around lg:flex-row flex-col gap-8 bg-gradient-to-tr from-color3 to-gray-900 ">
            {/* {Left part of card} */}
            <div className="relative lg:pt-0 pt-24 w-full lg:min-h-[70vh] flex flex-col justify-around lg:py-4">
              <div className="space-y-2 text-center lg:text-left w-full">
                <h2 className="lg:text-2xl font-bold sm:text-xl mt-2">
                  Voice Assistant Project
                </h2>
                {activeTab === "Feature" ? (
                  <p className="text-blue-300 text-[15px]">
                    {contentData.header.description}
                  </p>
                ) : (
                  <p></p>
                )}
              </div>

              {/* Tab Buttons */}
              <div className="absolute top-0 right-0 text-sm flex gap-2">
                {["Feature", "Author", "Other"].map((tab) => (
                  <button
                    key={tab}
                    onClick={() => setActiveTab(tab)}
                    className={`px-4 py-2 ${
                      activeTab === tab
                        ? "bg-gray-800 text-white"
                        : "bg-gray-600 text-gray-300"
                    } rounded-md`}
                  >
                    {tab}
                  </button>
                ))}
              </div>

              {/* Content Based on Active Tab */}
              <div className="mt-4 space-y-2">
                {activeTab === "Feature" && (
                  // Feat Section
                  <div className="max-md:space-y-2">
                    {contentData.features.map((feature, index) => (
                      <div
                        key={index}
                        className="flex items-center gap-2 text-blue-300 border-2 border-transparent hover:border-gray-600 px-4 py-2  bg-opacity-100 backdrop-blur-lg rounded-lg  max-md:text-[12px] text-[15px] mb-1 hover:scale-[1.02] hover:bg-gray-950 transition-all duration-300 cursor-pointer bg-gray-zinc-700"
                      >
                        <span className="text-green-500  p-2 px-[12px] rounded-full">
                          ✔
                        </span>
                        {feature}
                      </div>
                    ))}
                  </div>
                )}

                {activeTab === "Author" && (
                  // Author Section
                  <>
                    <div className="flex flex-col  gap-4 transition-transform duration-[700ms] transform translate-x-hover:translate-x-0 w-full xl:min-h-[24vh] text-white bg-gray-700 items-start justify-around p-4 mt-4 rounded-lg scale-100 hover:scale-105 ease-in-out">
                      <div className="flex justify-center items-center">
                        <img
                          className="duration-700 select-none group-hover:scale-110 flex-shrink-0 object-cover rounded-full group-hover:rounded-lg w-14 h-14 shadow-2xl mr-2"
                          src={
                            "https://media.licdn.com/dms/image/v2/D4D0BAQGHiS8IBFJk9A/company-logo_200_200/company-logo_200_200/0/1719256048724/neugence_technology_pvt_ltd_logo?e=1741219200&v=beta&t=xC0W-ZFGQuFg7RCTsfm42v6xzPOsB-WFcAeBYVwVhpY"
                          }
                          alt={contentData.authorDetails.author}
                        />
                        <p className="text-base text-left font-bold text-white cursor-pointer">
                          {contentData.authorDetails.projectLead}
                        </p>
                      </div>

                      <div className="flex-col items-start w-full">
                        <p className="text-lg text-left text-gray-300">
                          Position : {contentData.authorDetails.position}
                        </p>
                      </div>
                      <div
                        className={`flex transition-all duration-200 rounded-b-xl w-full left-0 items-center py-2 justify-around flex-row gap-3 cursor-pointer`}
                      >
                        <a
                          href={contentData.authorDetails.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LinkedIn className="transition-all duration-200 text-white hover:text-gray-400" />
                        </a>
                        <a
                          href={contentData.authorDetails.github}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <GitHub className="transition-all duration-200 text-white hover:text-gray-400" />
                        </a>
                      </div>
                    </div>
                    <div className=" flex flex-col gap-4 transition-transform duration-[700ms] transform translate-x-hover:translate-x-0 w-full xl:min-h-[24vh] text-white bg-gray-700 items-start justify-around p-4 mt-4 rounded-lg scale-100 hover:scale-105 ease-in-out ">
                      <div className="flex flex-col items-start w-full justify-around">
                        <div className="flex items-center w-full">
                          <ModelTrainingIcon className="mr-2" />
                          <p className="text-md text-left text-gray-300">
                            Models Used: {contentData.authorDetails.modelsUsed}
                          </p>
                        </div>
                        <div className="flex items-center w-full">
                          <CloudIcon className="mr-2" />
                          <p className="text-md text-left text-gray-300">
                            Model Hosted:{" "}
                            {contentData.authorDetails.modelHosted}
                          </p>
                        </div>
                        <div className="flex items-center w-full">
                          <GradeIcon className="mr-2" />
                          <p className="text-md text-left text-gray-300">
                            Accuracy : {contentData.authorDetails.accuracy}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {activeTab === "Other" && (
                  // Placeholder for Other Tab (if needed in the future)
                  <>
                    <div className="bg-gray-700 text-white text-center rounded-lg p-6 transition-transform transform scale-100 hover:scale-105 ease-in-out flex items-center">
                      <AttachMoneyIcon className="mr-2" />
                      {contentData.additionalFeatures.pricing}
                    </div>

                    <div className="bg-gray-700 text-white text-center rounded-lg p-6 transition-transform transform scale-100 hover:scale-105 ease-in-out flex items-center">
                      <CloudIcon className="mr-2" />
                      <p className="text-md text-left text-gray-300">
                        ActiveLearners: {contentData.scale.ActiveLearners}
                      </p>
                    </div>
                    <div className="grid grid-cols-1 gap-2 mt-6 mb-2">
                      <div className="bg-gray-700 text-white text-center rounded-lg p-6 transition-transform transform scale-100 hover:scale-105 ease-in-out flex items-center">
                        <StarIcon className="mr-2" />
                        {contentData.additionalFeatures.rating}
                      </div>
                      <div className="bg-gray-700 text-white text-center rounded-lg p-6 transition-transform transform scale-100 hover:scale-105 ease-in-out flex items-center">
                        <GradeIcon className="mr-2" />
                        <p className="text-md text-left text-gray-300">
                          Scale : {contentData.scale.Scale}
                        </p>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-6 mb-2">
                      {contentData.additionalInfo.map((info, index) => (
                        <div
                          key={index}
                          className="p-4 py-2 rounded-lg  border-2 border-gray-600 hover:border-2 hover:border-gray-300 text-center text-white hover:scale-[1.05] duration-500 hover:bg-gray-800 text-md bg-gray-700 bg-opacity-40 backdrop-blur-lg text-[16px]"
                        >
                          <p>{info.title}</p>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>

              {activeTab === "Feature" ? (
                <>
                  <div className="mt-2 flex space-y-2 text-[16px] max-md:text-[12px] items-center justify-between px-8 mb-2 w-full">
                    <div>
                      <button
                        onClick={() => onClickSelfStudyProject()}
                        className="mr-4 bg-richYellow p-2 rounded-md"
                      >
                        Learn More
                      </button>

                      <a href="/markdowntospeech">
                        <button className="hover:text-richYellow">
                          Explore
                        </button>
                      </a>
                    </div>
                    <div>
                      <button
                        className="mr-4 bg-blue-600 p-2   rounded-md"
                        onClick={() =>
                          window.open(`${discordInvite()}`, "_blank")
                        }
                      >
                        Join Waitlist
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <p></p>
              )}
            </div>
            <div className="w-full flex flex-col s lg:space-y-2 justify-center lg:min-h-[68vh] ">
              {activeTab === "Feature" ? (
                <>
                  <div className="flex gap-4">
                    <ReactPlayer
                      // url="https://blogs-images-and-videos.del1.vultrobjects.com/test1@gmail.com/cbedad86-bfb6-46fa-9440-2dd21a32c7ab/output.m3u8"
                      url="https://www.youtube.com/watch?v=uew-IM2Ed7Y"
                      controls
                      width="100%"
                      height="290px"
                      className="  "
                    />
                  </div>

                  <Markdowntoaudio
                    handleSubmit={handleSubmit}
                    markdownInput={markdownInput}
                    handleInputChange={handleInputChange}
                    loading={loading}
                    generatedText={generatedText}
                    audioUrl={audioUrl}
                  />
                </>
              ) : null}
              {activeTab === "Other" ? (
                <>
                  <div className="flex gap-4"></div>
                  <div className="grid grid-cols-2 md:grid-cols-2 gap-4 mt-4 text-sm">
                    <div className="bg-gray-700 text-white text-center rounded-lg p-4 px-4 transition-transform transform scale-100 hover:scale-105 ease-in-out flex items-center">
                      <HourglassEmptyIcon className="mr-2" />
                      {contentData.additionalFeatures.resourceSpent}
                    </div>
                    <div className="bg-gray-700 text-white text-center rounded-lg p-4 px-4 transition-transform transform scale-100 hover:scale-105 ease-in-out flex items-center">
                      <AccessTimeIcon className="mr-2" />
                      {contentData.additionalFeatures.timeTaken}
                    </div>
                    <div className="bg-gray-700 text-white text-center rounded-lg p-4 px-4 transition-transform transform scale-100 hover:scale-105 ease-in-out flex items-center">
                      <AttachMoneyIcon className="mr-2" />
                      {contentData.additionalFeatures.pricing}
                    </div>
                    <div className="bg-gray-700 text-white text-center rounded-lg p-4 px-4 transition-transform transform scale-100 hover:scale-105 ease-in-out flex items-center">
                      <StarIcon className="mr-2" />
                      {contentData.additionalFeatures.rating}
                    </div>
                  </div>
                  <Markdowntoaudio
                    handleSubmit={handleSubmit}
                    markdownInput={markdownInput}
                    handleInputChange={handleInputChange}
                    loading={loading}
                    generatedText={generatedText}
                    audioUrl={audioUrl}
                  />
                </>
              ) : null}
              {activeTab === "Author" ? (
                <>
                  <div className="flex gap-4">
                    <ReactPlayer
                      // url="https://blogs-images-and-videos.del1.vultrobjects.com/test1@gmail.com/cbedad86-bfb6-46fa-9440-2dd21a32c7ab/output.m3u8"
                      url="https://www.youtube.com/watch?v=uew-IM2Ed7Y"
                      controls
                     width="100%"
                      height="300px"
                      className=" border-2 border-gray-100  "
                    />
                  </div>

                  <Markdowntoaudio
                    handleSubmit={handleSubmit}
                    markdownInput={markdownInput}
                    handleInputChange={handleInputChange}
                    loading={loading}
                    generatedText={generatedText}
                    audioUrl={audioUrl}
                  />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
   
  );
};

export default ProjectDemo;
