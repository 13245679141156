import  { create }  from "zustand";
import axios from "axios";
import { getAxiosBaseUrl } from "../../../../config";

axios.defaults.baseURL = getAxiosBaseUrl();
axios.defaults.withCredentials = true;

const AuthorProfileStore = create((set, get) => ({
  HomeMenuData: {},
  experiences: [],
  profile:"",

  updateState: (key, value) => {
    set((state) => ({ ...state, [key]: value }));
  },

  fetchProfileData: async (email) => {
    if (!email) {
      console.error("User email is required");
      return;
    }
    try {
      const response = await axios.post(`/profile/fetch_profile`, { verifiedemail: email });
      set({ profile: response.data });
      console.log("Profile data fetched successfully", response.data);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  },

  // Creatingr for update profile
  createOrUpdateProfileData: async (profileData) => {
    if (!profileData.verifiedemail) {
      console.error("User email is required");
      return;
    }
    try {
      const response = await axios.post(`/profile/create_or_update_profile`, profileData);
      set({ profile: response.data.data });
      console.log("Profile created/updated successfully", response.data);
    } catch (error) {
      console.error("Error creating/updating profile:", error);
    }
  },

  // Delete user Profile data
  deleteProfileData: async (email) => {
    if (!email) {
      console.error("User email is required");
      return;
    }
    try {
      const response = await axios.post(`/profile/delete_profile`, { verifiedemail: email });
      set({ profile: "" }); // Reset profile data after deletion
      console.log("Profile deleted successfully", response.data);
    } catch (error) {
      console.error("Error deleting profile:", error);
    }
  },

  // Fetch user experiences 
  fetchExperiences: async (email) => {
    if (!email) {
      console.error("User email is required");
      return;
    }
    try {
      const response = await axios.post(`/profile/fetch_experiences`, { verifiedemail: email });
      set({ experiences: response.data.data });
      console.log("Experiences fetched successfully", response.data);
    } catch (error) {
      console.error("Error fetching experiences:", error);
    }
  },

  // Add user experience
  addExperienceData: async (experienceData) => {
    if (!experienceData.verifiedemail) {
      console.error("User email is required");
      return;
    }
    try {
      const response = await axios.post(`/profile/add_experience`, experienceData);
      console.log("Experience added successfully", response.data);
      get().fetchExperiences(experienceData.verifiedemail); // Refetch experiences after adding
    } catch (error) {
      console.error("Error adding experience:", error);
    }
  },

  // Update users experience
  updateExperienceData: async (email, experienceId, updatedExperience) => {
    if (!email || !experienceId) {
      console.error("User email and experience ID are required");
      return;
    }
    try {
      const response = await axios.post(`/profile/update_experience`, {
        verifiedemail: email,
        experienceId,
        ...updatedExperience,
      });
      console.log("Experience updated successfully", response.data);
      get().fetchExperiences(email); // Refetch experiences after updating
    } catch (error) {
      console.error("Error updating experience:", error);
    }
  },

  // Delete user experience
  deleteExperienceData: async (email, experienceId) => {
    if (!email || !experienceId) {
      console.error("User email and experience ID are required");
      return;
    }
    try {
      const response = await axios.post(`/profile/delete_experience`, { verifiedemail: email, experienceId });
      console.log("Experience deleted successfully", response.data);
      get().fetchExperiences(email); // Refetch experiences after deletion
    } catch (error) {
      console.error("Error deleting experience:", error);
    }
  },

  // Updates menu data via verified email
  updatemenudata: async (email, menuToUpdate) => {
    if (!email || !menuToUpdate) {
      console.error("Email and menu data are required for updating.");
      return;
    }
    try {
      const response = await axios.post(`/menu/update_menu_data/${email}`, {
        updatedData: menuToUpdate,
      });
      console.log("Menu data updated successfully for:", email);
      return response.data;
    } catch (error) {
      console.error(`Error updating menu data for ${email}:`, error);
      throw error;
    }
  },

  // Fetches menu data based on verified email
  fetchMenuData: async (email) => {
    if (!email) return;
    try {
      const response = await axios.post(
        `/menu/fetch_menu_data/${email}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      set({ HomeMenuData: response.data });
      console.log("Menu data fetched successfully", response.data);
    } catch (error) {
      console.error("Error fetching menu data:", error);
    }
  },
}));

export default AuthorProfileStore;
