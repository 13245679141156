import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { discordInvite } from '../../../config';

const courseDetails = [
  {
    title: "4-week (Fast track)",
    description: "Focused learning without a project",
    price: "$35",
    duration: "4 weeks",
    projects: "No projects",
    features: [
      'Private forum access',
      'Member resources',
      'Entry to annual AI conference',
      'Certification on completion',
    ],
  },
  {
    title: "6-week (Medium)",
    description: "Learn AI with 1 project included",
    price: "$43",
    duration: "6 weeks",
    projects: "1 AI project",
    features: [
      'Private forum access',
      'Member resources',
      'Entry to annual AI conference',
      '1 hands-on project',
      'Certification on completion',
    ],
  },
  {
    title: "8-week (Ai Thon)",
    description: "Full course with 2 hands-on projects",
    price: "$79",
    duration: "8 weeks",
    projects: "2 AI projects",
    features: [
      'Private forum access',
      'Member resources',
      'Entry to annual AI conference',
      '2 hands-on projects',
      'Certification on completion',
      'Chances of a paid internship',
      'Community support and mentoring',
    ],
  },
];

const whyChooseUs = [
  {
    title: "Free Tools",
    description: "Gain access to AI tools like Markdown to Speech, Text to Image, and Text to Video, free of charge.",
    emoji: "🛠️",
  },
  {
    title: "Free Video Lectures",
    description: "All users get free access to AI video lectures, covering beginner to advanced topics.",
    emoji: "🎥",
  },
  {
    title: "Community Support",
    description: "Join an active AI community for discussions, problem-solving, and mentorship.",
    emoji: "🤝",
  },
  {
    title: "Certification on Completion",
    description: "Receive a verified certification demonstrating your AI skills to employers.",
    emoji: "📜",
  },
];

export default function Pricing() {
  return (
    <div className="py-6 sm:py-32" id="pricing">
      <div className="mx-auto max-w-[80vw] max-md:w-full px-6 lg:px-8">
        {/* Pricing Cards */}
        <div className="lg:flex lg:flex-wrap lg:gap-8 lg:justify-center mt-4">
          {courseDetails.map((course, index) => (
            <div
              key={course.title}
              className={`rounded-2xl mb-4 bg-white py-10 text-center shadow-lg ring-1 ring-inset ring-gray-200  transition-all duration-500 ease-out transform hover:scale-105 hover:shadow-xl mt-4 lg:mt-0 min-w-[310px] max-w-[340px] p-6`}
            >
              {index === 1 && (
                <div className="absolute text-lg top-0 right-0 bg-richYellow text-white px-4 py-2 rounded-bl-lg rounded-tr-lg font-bold">
                  Popular
                </div>
              )}
              <div className="mx-auto  px-2">
                <h3 className="text-3xl font-semibold tracking-tight text-gray-900">{course.title}</h3>
                <p className="mt-2 text-gray-600">{course.description}</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">{course.price}</span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">USD</span>
                </p>
                <p className="mt-2 text-gray-600">{course.duration} | {course.projects}</p>
                <a
                  href={discordInvite()}
                  className="mt-10 block px-4 rounded-md bg-richYellow  py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-yellow-400 hover:shadow-lg transition-all duration-500 ease-out"
                  target='_blank'
                >
                  Enroll Now in Discord
                </a>
                <div className="h-px bg-gray-200 my-8"></div>
                <ul role="list" className="mt-8 text-sm leading-6 text-gray-600">
                  {course.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckCircleIcon aria-hidden="true" className="h-6 w-5 flex-none text-richYellow" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        {/*DO NOT DELETE, THIS IS FUTURE COMPONENT REQUIRED*/}
        {/* Why Choose Us Section */}
        {/*<div className="mt-16">*/}
        {/*  <h3 className="text-3xl font-bold text-center mb-8">Why Choose Us?</h3>*/}
        {/*  <div className="grid grid-cols-1 md:grid-cols-2 gap-8">*/}
        {/*    {whyChooseUs.map((item, index) => (*/}
        {/*      <div*/}
        {/*        key={item.title}*/}
        {/*        className="bg-white shadow-lg p-6 rounded-lg transition-all duration-300 ease-out hover:shadow-xl hover:scale-105"*/}
        {/*      >*/}
        {/*        <h4 className="text-xl font-semibold text-gray-800 mb-4">*/}
        {/*          {item.emoji} {item.title}*/}
        {/*        </h4>*/}
        {/*        <p className="text-gray-600">{item.description}</p>*/}
        {/*      </div>*/}
        {/*    ))}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}
