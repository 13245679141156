import React, { useEffect, useState } from "react";
import DriveFileRenameOutlineSharpIcon from "@mui/icons-material/DriveFileRenameOutlineSharp";
import { dummyAuthorData } from "./AuthorData";
import ProjectCard from "../../molecules/ProjectCard";
import EditModal from "./EditModal";
import AuthorSidebar from "./AuthorSidebar";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import VideoGallery from "../../Media/VideoGallery";
import MegaNotes from "../../Workspace/MegaNotes";
import AuthorProfileStore from "./store/AuthorProfileStore";

const AuthorProfile = ({ email, verifiedemail }) => {

  const { profile, fetchProfileData, createOrUpdateProfileData } = AuthorProfileStore();

  useEffect(() => {
    // Fetch profile data for a specific email
    fetchProfileData("testuser@examle.com");
  }, [fetchProfileData]);



  const [author, setAuthor] = useState(dummyAuthorData);
  const [showModal, setShowModal] = useState(false);
  const [currentSection, setCurrentSection] = useState(null);
  const [modalContent, setModalContent] = useState({});
  const [bgColor, setBgColor] = useState("bg-color2");
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingBio, setIsEditingBio] = useState(false);

  const openModal = (section, data = {}) => {
    setCurrentSection(section);
    setModalContent(data);
    setShowModal(true);
  };

  const handleDelete = (section, index) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      const updatedAuthor = { ...author };
      updatedAuthor[section].splice(index, 1);
      setAuthor(updatedAuthor);
    }
  };

  const handleSave = () => {
    const updatedAuthor = { ...author };
    if (currentSection === "projects") {
      const projects = [...updatedAuthor.projects];
      if (modalContent.id) {
        projects[modalContent.id] = modalContent;
      } else {
        projects.push(modalContent); // Add new project
      }
      updatedAuthor.projects = projects;
    } else if (currentSection === "pastExperience") {
      const experiences = [...updatedAuthor.pastExperience];
      if (modalContent.id) {
        experiences[modalContent.id] = modalContent;
      } else {
        experiences.push(modalContent); // Add new experience
      }
      updatedAuthor.pastExperience = experiences;
    } else if (currentSection === "aboutMe") {
      updatedAuthor.About_me = modalContent;
    }
    setAuthor(updatedAuthor);
    setShowModal(false);
  };

  return (
    <div className="min-h-screen w-full flex flex-col xl:flex-row gap-8 mt-20">
      {author ? (
        <>
          <AuthorSidebar
            author={author}
            bgColor={bgColor}
            setBgColor={setBgColor}
            handleProfileImageChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                const imageUrl = URL.createObjectURL(file);
                setAuthor((prev) => ({ ...prev, profilePhoto: imageUrl }));
              }
            }}
            setAuthor={setAuthor}
            isEditingName={isEditingName}
            setIsEditingName={setIsEditingName}
            isEditingBio={isEditingBio}
            setIsEditingBio={setIsEditingBio}
          />

          {/* Main content area */}
          <div className="flex flex-col w-full xl:w-[75%] gap-8 lg:p-6 p-4 ">
            {/* Save changes button */}
            <div className="flex justify-end fixed bottom-6 right-6 z-20">
              <button
                className="px-6 py-2 bg-green-500 text-white rounded shadow-md transition hover:bg-green-600"
                onClick={() => alert("All changes saved!")}
              >
                Save
              </button>
            </div>

            {/* About Me Section */}
            <div className="bg-white shadow-md rounded-lg p-6 w-full">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">About Me</h2>
                <button
                  className="p-2 bg-blue-500 text-white rounded-full"
                  onClick={() => openModal("aboutMe", author.About_me)}
                >
                  <DriveFileRenameOutlineSharpIcon />
                </button>
              </div>
              <p>{author.About_me}</p>
            </div>

            {/* Video Gallery Section */}
            <div className="relative bg-white shadow-md rounded-lg p-6 w-full">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">My Videos</h2>
                <div className="flex space-x-4">
                  <button className="px-4 py-2 bg-blue-500 text-white rounded shadow-md transition hover:bg-blue-600">
                    Add Video
                  </button>
                  <button className="px-4 py-2 bg-green-500 text-white rounded shadow-md transition hover:bg-green-600">
                    Share Video
                  </button>
                </div>
              </div>
              <VideoGallery verifiedemail={verifiedemail} />
            </div>

            {/* Notes Section */}
            <div className="relative bg-white shadow-md rounded-lg p-6 w-full h-[50vh]">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">My Notes</h2>
                <div className="flex space-x-4">
                  <button className="px-4 py-2 bg-blue-500 text-white rounded shadow-md transition hover:bg-blue-600">
                    Add Notes
                  </button>
                  <button className="px-4 py-2 bg-green-500 text-white rounded shadow-md transition hover:bg-green-600">
                    Share Notes
                  </button>
                </div>
              </div>
              <MegaNotes verifiedemail={verifiedemail} />
            </div>

            {/* Projects Section */}
            <div className="bg-white shadow-md rounded-lg p-6 w-full">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">Projects</h2>
                <button
                  className="px-4 py-2 bg-blue-500 text-white rounded shadow-md transition hover:bg-blue-600"
                  onClick={() => openModal("projects", {})}
                >
                  +
                </button>
              </div>
              <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {author.projects.map((project, idx) => (
                  <div key={idx} className="relative">
                    <ProjectCard course={author.course} />
                    <button
                      className="absolute top-4 right-4 bg-gray-900 p-2 rounded-md text-white"
                      onClick={() => handleDelete("projects", idx)}
                    >
                      <DeleteSharpIcon />
                    </button>
                    <button
                      className="absolute top-4 left-4 bg-gray-900 p-2 rounded-md text-white"
                      onClick={() =>
                        openModal("projects", { ...project, id: idx })
                      }
                    >
                      <DriveFileRenameOutlineSharpIcon />
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {/* Past Experience Section */}
            <div className="bg-white shadow-md rounded-lg p-6 w-full">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">Past Experience</h2>
                <button
                  className="px-4 py-2 bg-blue-500 text-white rounded shadow-md transition hover:bg-blue-600"
                  onClick={() => openModal("pastExperience", {})}
                >
                  Add Experience
                </button>
              </div>
              <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
                {author.pastExperience.map((experience, idx) => (
                  <div
                    key={idx}
                    className="relative bg-gray-100 p-4 rounded-lg shadow-md"
                  >
                    <h3 className="text-lg font-semibold">{experience.role}</h3>
                    <p>{experience.company}</p>
                    <p className="text-gray-500">{experience.duration}</p>
                    <button
                      className="absolute top-4 right-4 bg-gray-900 p-2 rounded-md text-white"
                      onClick={() => handleDelete("pastExperience", idx)}
                    >
                      <DeleteSharpIcon />
                    </button>
                    <button
                      className="absolute top-4 left-4 bg-gray-900 p-2 rounded-md text-white"
                      onClick={() =>
                        openModal("pastExperience", { ...experience, id: idx })
                      }
                    >
                      <DriveFileRenameOutlineSharpIcon />
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {/* Additional Sections */}
            <div className="bg-white shadow-md rounded-lg p-6 w-full">
              <h2 className="text-xl font-semibold mb-4">1:1 Meetings</h2>
              <div className="flex space-x-4">
                <button className="px-4 py-2 bg-blue-500 text-white rounded shadow-md transition hover:bg-blue-600">
                  Discovery Call
                </button>
                <button className="px-4 py-2 bg-green-500 text-white rounded shadow-md transition hover:bg-green-600">
                  Instant Chat
                </button>
              </div>
            </div>
          </div>

          {/* Modal */}
          {showModal && (
            <EditModal
              currentSection={currentSection}
              modalContent={modalContent}
              setModalContent={setModalContent}
              handleSave={handleSave}
              setShowModal={setShowModal}
            />
          )}
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default AuthorProfile;
