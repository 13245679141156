import React from "react";
import HeadindText from "../pages/Utils/HeadindText";
import svgImg from './RelevanceSvg.svg' 
const highlights = [
  {
    title: "Undergraduates from the United States",
    description:
      "Our cohort includes ambitious undergraduate students from top universities in the United States. They bring diverse perspectives, fresh ideas, and an eagerness to learn advanced concepts in cutting-edge domains. This global mix fosters a collaborative environment that promotes knowledge-sharing and enhances the learning experience for everyone involved.",
  },
  {
    title: "Experienced Professionals with US Masters Degrees",
    description:
      "Our learners include professionals with four years of industry experience and advanced degrees from renowned universities in the US. Their technical acumen, combined with practical exposure, adds immense value to the learning process. They aim to refine their skills, adopt new technologies, and transition to leadership roles in multinational corporations. ",
  },
  {
    title: "Mentors with Decades of Teaching Expertise",
    description:
      "We are privileged to have mentors with over 15 years of teaching experience, including alumni from premier institutions like BITS Pilani. Their deep subject matter expertise and proven pedagogy help simplify complex topics, making them accessible and actionable for learners. Their mentorship sets a strong foundation for learners' success.",
  },
  {
    title: "Senior Scientists from Bangalore",
    description:
      "Our program attracts seasoned professionals like senior scientists from Bangalore, who are well-versed in advanced research and development. Their participation brings a high level of technical insight and innovation to our community. Their contributions inspire others and create a vibrant ecosystem of learning and growth.",
  },
  {
    title: "IIT Bombay M.Tech Graduates with Professional Expertise",
    description:
      "Our community includes M.Tech graduates from IIT Bombay who combine their technical education with professional experience at leading organizations like American Express.They serve as role models for aspiring professionals and elevate the overall quality of discussions and projects within the cohort.",
  },
  {
    title: "IIM Graduates Pursuing Excellence",
    description:
      "Joining us are graduates from premier business schools like IIMs, who aim to complement their managerial expertise with technical skills.  Their presence bridges the gap between technical depth and business acumen, fostering a holistic learning environment that benefits all participants.",
  },
  {
    title: "NIT Hamirpur Alumni",
    description:
      "Our cohort also includes graduates from institutions like NIT Hamirpur, who bring a solid foundation in engineering and a passion for innovation.  Their technical background and collaborative mindset make them valuable contributors to our program, enriching the overall learning experience for everyone involved.",
  },
];

const WhyRelevance = () => {
  return (
    <div className="py-16 px-4 w-[80vw] max-md:w-[98vw]">
      
      <h3 className="text-3xl font-bold text-center mb-8 ">
      <HeadindText Heading={` Why We Are Relevant?`} />
      </h3>

      <p className="text-lg text-gray-600 max-w-3xl mx-auto text-center mb-12">
        Our program stands out for its high standards and unique cohort. From
        seasoned professionals to ambitious students, each participant brings
        unparalleled expertise and ambition. Here's why we are unique and why
        our class is considered among the best.
      </p>
     
      <div className="flex flex-wrap justify-center items-center gap-8">

        {highlights.map((item, index) => (
         <div
         key={index}
         className="group bg-white shadow-md p-6 rounded-lg border-t-4 border-indigo-500 transition-all duration-500 ease-out hover:scale-105 hover:shadow-lg min-w-[300px] max-w-[360px] min-h-[350px] hover:bg-indigo-500 cursor-pointer relative overflow-hidden"
       >
         <h4 className="text-xl font-semibold text-gray-800 mb-4 flex items-center group-hover:text-white z-[2] relative">
           {item.title}
         </h4>
         <p className="text-gray-600 group-hover:text-gray-50 max-md:text-[16px] z-[2] relative">
           {item.description}
         </p>
         <img
           src={svgImg}
           className="absolute bottom-0 left-0 w-full h-auto opacity-0 group-hover:opacity-70 transition-opacity duration-500 z-[1]"
         />
       </div>
       
        ))}
      </div>
    </div>
  );
};

export default WhyRelevance;
