import React, { useState } from "react";
import HeroImage from "./image_main.jpg";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import BrainIcon from "@mui/icons-material/Psychology";
import RobotIcon from "@mui/icons-material/SmartToy";
import EducationIcon from "@mui/icons-material/School";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import axios from "axios";
import { getAxiosBaseUrl } from "../../config";
import "./Hero.css";
import { useNavigate } from "react-router-dom";
import HeadindText from "../pages/Utils/HeadindText";

const Hero = ({ scrollToEmailSubscribe, setEmailData }) => {
  const navigate = useNavigate();
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);
  const [isDemoModalOpen, setDemoModalOpen] = useState(false);
  const [formData, setFormData] = useState({ name: "", email: "" });

  const handleEmailChange = (e) => setEmailData(e.target.value);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleVideoModalToggle = () => setVideoModalOpen(!isVideoModalOpen);
  const handleDemoModalToggle = () => setDemoModalOpen(!isDemoModalOpen);

  const handleDemoSubmit = () => {
    if (formData.name && formData.email) {
      axios.defaults.baseURL = getAxiosBaseUrl();
      axios
        .post("/acehubdemorequest", {
          name: formData.name,
          email: formData.email,
          source: "acehub.ai",
        })
        .then((response) => {
          alert("Congratulations! 🎉 Your form has been submitted.");
          setDemoModalOpen(false);
          setFormData({ name: "", email: "" });
        })
        .catch((err) => console.log(err));
    } else {
      alert("Please fill out all fields.");
    }
  };
  const handleCloseModal = () => {
    setVideoModalOpen(false); // Assuming this state controls modal visibility
    setDemoModalOpen(false); // Adjust based on which modal is open
  };

  return (
    <div className="flex items-center justify-center w-full relative min-h-[70vh] bg-gradient-to-tr from-[#212f4c] to-[#1f2b45] text-white shadow-lg overflow-hidden flex-col">
      <div className="absolute inset-0 grid grid-cols-12 grid-rows-6 opacity-25">
        {Array.from({ length: 80 }).map((_, colIdx) => (
          <div
            key={colIdx}
            className="border-[2px] border-white  border-opacity-[0.15]"
          ></div>
        ))}
      </div>

      <div className="absolute top-10 left-5 w-60 h-60 bg-white opacity-10 rounded-full filter blur-2xl "></div>
      <div className="absolute top-2 right-10 w-64 h-64 bg-white opacity-20 rounded-full filter blur-3xl "></div>
      <div className="absolute top-40 left-[40vw] w-64 h-64 bg-white opacity-20 rounded-full filter blur-3xl "></div>

      {isVideoModalOpen && (
        <div
          onClick={handleCloseModal}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 modal-overlay"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="relative w-[90%] sm:w-[80%] lg:max-w-5xl bg-gray-200 rounded-lg flex flex-col lg:flex-row shadow-2xl modal-content"
          >
            <button
              onClick={handleVideoModalToggle}
              className="absolute top-4 right-4 text-white text-2xl font-bold hover:text-red-500"
            >
              ✖
            </button>

            {/* Left Section */}
            <div className="lg:w-3/5 w-full p-4 lg:pr-6 flex flex-col items-center bg-gradient-to-r from-blue-500 to-purple-500">
              <iframe
                width="100%"
                height="200"
                src="https://www.youtube.com/embed/uew-IM2Ed7Y"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="rounded-lg shadow-lg sm:h-[300px] lg:h-[315px]"
              ></iframe>
            </div>

            {/* Right Section */}
            <div className="lg:w-2/5 w-full flex flex-col justify-center text-white p-4 lg:pl-6 bg-gradient-to-tr from-[#212f4c] to-[#1f2b45] border-2">
              <h3 className="text-2xl sm:text-3xl font-bold text-center mb-4 text-richYellow">
                Explore AceHub
              </h3>
              <ul className="text-white space-y-4">
                <li className="flex items-center space-x-2 hover:scale-105 hover:shadow-lg transition-transform duration-300 p-2 px-4">
                  <span className="text-2xl">📚</span>
                  <span>
                    Comprehensive{" "}
                    <span className="bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent font-semibold">
                      AI learning experiences
                    </span>
                  </span>
                </li>
                <li className="flex items-center space-x-2 hover:scale-105 hover:shadow-lg transition-transform duration-300 p-2 px-4">
                  <span className="text-2xl">🚀</span>
                  <span>
                    Hands-on real-world{" "}
                    <span className="bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent font-semibold">
                      AI/ML projects
                    </span>
                  </span>
                </li>
                <li className="flex items-center space-x-2 hover:scale-105 hover:shadow-lg transition-transform duration-300 p-2 px-4">
                  <span className="text-2xl">🤝</span>
                  <span>
                    Mentorship from{" "}
                    <span className="bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent font-semibold">
                      industry experts
                    </span>
                  </span>
                </li>
                <li className="flex items-center space-x-2 hover:scale-105 hover:shadow-lg transition-transform duration-300 p-2 px-4">
                  <span className="text-2xl">💼</span>
                  <span>
                    Career-oriented programs with{" "}
                    <span className="bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent font-semibold">
                      job-ready skills
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {isDemoModalOpen && (
        <div
          onClick={handleCloseModal}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 modal-overlay"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className=" modal-content relative w-[80%] max-w-md bg-gradient-to-r from-gray-100 to-gray-300 rounded-lg p-8 shadow-2xl"
          >
            <button
              onClick={handleDemoModalToggle}
              className="absolute top-4 right-4 text-gray-800 text-2xl font-bold hover:text-red-500"
            >
              ✖
            </button>

            <h2 className="text-3xl font-bold text-richYellow mb-6 text-center">
              Add Your Details
            </h2>

            <form onSubmit={(e) => e.preventDefault()} className="space-y-4">
              <label className="block text-gray-700 text-lg">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Name"
                  className="w-full mt-2 p-2 px-4 border border-gray-300 rounded-lg shadow-inner focus:outline-none focus:ring-2 focus:ring-purple-100"
                  required
                />
              </label>

              <label className="block text-gray-700 text-lg">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Email"
                  className="w-full mt-2 p-2 px-4 border border-gray-300 rounded-lg shadow-inner focus:outline-none focus:ring-2 focus:ring-purple-100"
                  required
                />
              </label>

              <button
                onClick={handleDemoSubmit}
                className="w-full py-3 bg-gradient-to-r from-yellow-500 to-orange-500 text-white font-semibold text-lg rounded-lg shadow-md hover:bg-yellow-600 hover:scale-105 transition-all duration-300"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      )}

      <div className="relative z-10 w-screen lg:w-[88vw] flex flex-col lg:flex-row justify-between items-center px-10 md:p-4 py-6 md:py-10 md:w-[100vw] max-md:p-4">
        <div className="text-center md:text-left w-full lg:w-7/12 p-6 md:p-8 max-md:p-4  shadow-lg">
          <h1 className="text-3xl md:text-4xl font-extrabold mb-6 text-left text-gray-100 bg-clip-text ">
            Upskill in AI
          </h1>
          <p
            style={{
              background:
                "linear-gradient(90deg, rgb(255, 166, 97), rgb(162 144 144), rgb(255 23 23))",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
            className="text-md md:text-md mb-2 text-left leading-relaxed opacity-85"
          >
            A true work experience-style learning environment, not just watch
            videos but write code in GPUs.
            <br />
            {/*<span className="font-normal">Limited seats: </span> only 5 of 35 left*/}
          </p>
          <div className="text-left text-gray-100 mb-6 pt-4">
            <h2 className="text-lg font-semibold mb-4">Available Tracks:</h2>

            <div className="grid grid-cols-2 md:grid-cols-2 gap-4 pl-4">
              <div className="flex items-center space-x-2 text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 text-richYellow"
                  fill="none"
                  viewBox="0 0 64 64"
                >
                  <path d="M6 2v60" stroke="currentColor" stroke-width="4" />
                  <rect x="8" y="2" width="10" height="8" fill="currentColor" />
                  <rect x="18" y="2" width="10" height="8" fill="white" />
                  <rect
                    x="28"
                    y="2"
                    width="10"
                    height="8"
                    fill="currentColor"
                  />
                  <rect x="38" y="2" width="10" height="8" fill="white" />

                  <rect x="8" y="10" width="10" height="8" fill="white" />
                  <rect
                    x="18"
                    y="10"
                    width="10"
                    height="8"
                    fill="currentColor"
                  />
                  <rect x="28" y="10" width="10" height="8" fill="white" />
                  <rect
                    x="38"
                    y="10"
                    width="10"
                    height="8"
                    fill="currentColor"
                  />

                  <rect
                    x="8"
                    y="18"
                    width="10"
                    height="8"
                    fill="currentColor"
                  />
                  <rect x="18" y="18" width="10" height="8" fill="white" />
                  <rect
                    x="28"
                    y="18"
                    width="10"
                    height="8"
                    fill="currentColor"
                  />
                  <rect x="38" y="18" width="10" height="8" fill="white" />
                </svg>
                <span>1st-3rd Years</span>
              </div>
              <div className="flex items-center space-x-2 text-gray-200 -ml-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 text-richYellow"
                  fill="none"
                  viewBox="0 0 64 64"
                >
                  <path d="M6 2v60" stroke="currentColor" stroke-width="4" />
                  <rect x="8" y="2" width="10" height="8" fill="currentColor" />
                  <rect x="18" y="2" width="10" height="8" fill="white" />
                  <rect
                    x="28"
                    y="2"
                    width="10"
                    height="8"
                    fill="currentColor"
                  />
                  <rect x="38" y="2" width="10" height="8" fill="white" />

                  <rect x="8" y="10" width="10" height="8" fill="white" />
                  <rect
                    x="18"
                    y="10"
                    width="10"
                    height="8"
                    fill="currentColor"
                  />
                  <rect x="28" y="10" width="10" height="8" fill="white" />
                  <rect
                    x="38"
                    y="10"
                    width="10"
                    height="8"
                    fill="currentColor"
                  />

                  <rect
                    x="8"
                    y="18"
                    width="10"
                    height="8"
                    fill="currentColor"
                  />
                  <rect x="18" y="18" width="10" height="8" fill="white" />
                  <rect
                    x="28"
                    y="18"
                    width="10"
                    height="8"
                    fill="currentColor"
                  />
                  <rect x="38" y="18" width="10" height="8" fill="white" />
                </svg>
                <span>Working Professionals</span>
              </div>
              <div className="flex items-center space-x-2 text-gray-200">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 text-richYellow"
                  fill="none"
                  viewBox="0 0 64 64"
                >
                  <path d="M6 2v60" stroke="currentColor" stroke-width="4" />
                  <rect x="8" y="2" width="10" height="8" fill="currentColor" />
                  <rect x="18" y="2" width="10" height="8" fill="white" />
                  <rect
                    x="28"
                    y="2"
                    width="10"
                    height="8"
                    fill="currentColor"
                  />
                  <rect x="38" y="2" width="10" height="8" fill="white" />

                  <rect x="8" y="10" width="10" height="8" fill="white" />
                  <rect
                    x="18"
                    y="10"
                    width="10"
                    height="8"
                    fill="currentColor"
                  />
                  <rect x="28" y="10" width="10" height="8" fill="white" />
                  <rect
                    x="38"
                    y="10"
                    width="10"
                    height="8"
                    fill="currentColor"
                  />

                  <rect
                    x="8"
                    y="18"
                    width="10"
                    height="8"
                    fill="currentColor"
                  />
                  <rect x="18" y="18" width="10" height="8" fill="white" />
                  <rect
                    x="28"
                    y="18"
                    width="10"
                    height="8"
                    fill="currentColor"
                  />
                  <rect x="38" y="18" width="10" height="8" fill="white" />
                </svg>
                <span>Final Year Students</span>
              </div>
              <div className="flex items-center space-x-2 text-gray-200 -ml-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 text-richYellow"
                  fill="none"
                  viewBox="0 0 64 64"
                >
                  <path d="M6 2v60" stroke="currentColor" stroke-width="4" />
                  <rect x="8" y="2" width="10" height="8" fill="currentColor" />
                  <rect x="18" y="2" width="10" height="8" fill="white" />
                  <rect
                    x="28"
                    y="2"
                    width="10"
                    height="8"
                    fill="currentColor"
                  />
                  <rect x="38" y="2" width="10" height="8" fill="white" />

                  <rect x="8" y="10" width="10" height="8" fill="white" />
                  <rect
                    x="18"
                    y="10"
                    width="10"
                    height="8"
                    fill="currentColor"
                  />
                  <rect x="28" y="10" width="10" height="8" fill="white" />
                  <rect
                    x="38"
                    y="10"
                    width="10"
                    height="8"
                    fill="currentColor"
                  />

                  <rect
                    x="8"
                    y="18"
                    width="10"
                    height="8"
                    fill="currentColor"
                  />
                  <rect x="18" y="18" width="10" height="8" fill="white" />
                  <rect
                    x="28"
                    y="18"
                    width="10"
                    height="8"
                    fill="currentColor"
                  />
                  <rect x="38" y="18" width="10" height="8" fill="white" />
                </svg>
                <span>Industry Partners</span>
              </div>
            </div>
          </div>

          <ul className="list-none text-sm md:text-base space-y-4 text-gray-400 cursor-pointer pt-6 text-left">
            <li className="hover:text-gray-100 transition-all ">
              <span className="text-richYellow">⚡ Weekly demos,</span> project
              tracks, and peer discussions.
            </li>
            {/*<li className="hover:text-gray-100 transition-all">*/}
            {/*  <span className="text-richYellow">⚡ Progressive difficulty </span>*/}
            {/*  for all skill levels, from beginner to advanced.*/}
            {/*</li>*/}
            <li className="hover:text-gray-100 transition-all">
              <span className="text-richYellow">⚡ Mentorship</span> from top
              industry experts.
            </li>
            {/*<li className="hover:text-gray-100 transition-all">*/}
            {/*  <span className="text-richYellow">⚡ Live AI/ML projects </span>*/}
            {/*  designed to meet real-world industry demands.*/}
            {/*</li>*/}
            <li className="hover:text-gray-100 transition-all">
              <span className="text-richYellow">
                ⚡ Real-world AI/ML projects{" "}
              </span>
              tailored to industry needs.
            </li>
          </ul>

          <div className="flex gap-6 md:gap-4 items-center justify-around md:justify-start w-full mt-6">
            <button
              onClick={handleDemoModalToggle}
              className="md:w-auto px-6 py-3 md:py-4 rounded-full text-white font-medium hover:scale-110 bg-gradient-to-r from-blue-500 to-purple-500 transition-transform duration-500 shadow-2xl"
            >
              <span className="flex items-center text-white justify-center font-semibold text-lg">
                Join Waitlist
              </span>
            </button>
            <a
              onClick={() => navigate(`/projects`)}
              className=" md:w-auto px-6 py-3 md:py-4 rounded-full text-white font-medium hover:scale-110 bg-gradient-to-r from-blue-500 to-purple-500 transition-transform duration-500 shadow-2xl cursor-pointer"
            >
              Explore Projects
            </a>
          </div>
        </div>

        <div className="lg:w-1/2 md:w-3/4 sm:w-full mt-10 lg:mt-0 md:mt-5 sm:mt-3 p-8 h-[80vh] md:h-[70vh] max-sm:h-[420px] max-sm:w-[400px]">
          <div className="relative mx-auto shadow-inner-lg max-h-800px">
            <img
              src={HeroImage}
              alt="Person"
              className="transform hover:scale-105 transition duration-300 md:min-w-[540px] aspect-square object-cover rounded-[10rem] rounded-tr-[14rem] md:rounded-[5rem] sm:rounded-[2rem]"
            />
            <div
              onClick={handleVideoModalToggle}
              className="absolute top-[-10px] right-[10px] md:top-[-15px] md:right-[20px] sm:top-[10px] sm:right-[10px] bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-lg lg:px-10 md:px-6 px-4 lg:py-4 md:py-3 py-2 flex items-center space-x-2 sm:space-x-1 text-lg md:text-base sm:text-sm shadow-lg hover:scale-105 transition-transform duration-500 cursor-pointer"
            >
              <span className="mr-2 sm:mr-1">Easy Project</span>
              <button
                onClick={handleVideoModalToggle}
                className="flex items-center space-x-2 bg-transparent border-2 border-white px-4 py-2 md:px-3 md:py-1 sm:px-2 sm:py-1 rounded-full text-white shadow-lg hover:bg-white hover:text-blue-500 transition-colors duration-300"
              >
                <PlayArrowIcon />
              </button>
            </div>
          </div>
        </div>
        
      </div>
      <div className="grid grid-cols-1 md:grid-cols-5 text-center md:text-left text-gray-300 p-4 w-[90%] md:w-[75vw] max-md:gap-4  mb-8 h-auto max-md:w-full gap-2">
        <div className="bg-gray-100 bg-opacity-[0.1] p-6 rounded-lg border-transparent  hover:border-gray-500  hover:border-[4px] border-[4px] shadow-lg flex flex-col items-center justify-center transform transition duration-300 hover:scale-105">
          <p className="text-4xl font-bold text-blue-50">70+</p>
          <p className="text-sm mt-2 text-gray-200">
            Real-world projects in AI/ML domains
          </p>
        </div>
        <div className="bg-gray-100 bg-opacity-[0.1] p-6 rounded-lg border-transparent  hover:border-gray-500  hover:border-[4px] border-[4px] shadow-lg flex flex-col items-center justify-center transform transition duration-300 hover:scale-105">
          <p className="text-4xl font-bold text-green-50">100+</p>
          <p className="text-sm mt-2 text-gray-200">
            Movies equivalent learning content
          </p>
        </div>
        <div className="bg-gray-100 bg-opacity-[0.1] p-6 rounded-lg border-transparent  hover:border-gray-500  hover:border-[4px] border-[4px] shadow-lg flex flex-col items-center justify-center transform transition duration-300 hover:scale-105">
          <p className="text-4xl font-bold text-purple-50">90%</p>
          <p className="text-sm mt-2 text-gray-200">
            Students improved their AI job readiness
          </p>
        </div>
        <div className="bg-gray-100 bg-opacity-[0.1] p-6 rounded-lg border-transparent  hover:border-gray-500  hover:border-[4px] border-[4px] shadow-lg flex flex-col items-center justify-center transform transition duration-300 hover:scale-105">
          <p className="text-4xl font-bold text-orange-50">10+</p>
          <p className="text-sm mt-2 text-gray-200">Industry mentors</p>
        </div>
        <div className="bg-gray-100 bg-opacity-[0.1] p-6 rounded-lg border-transparent  hover:border-gray-500  hover:border-[4px] border-[4px] shadow-lg flex flex-col items-center justify-center transform transition duration-300 hover:scale-105">
          <p className="text-4xl font-bold text-yellow-50">200+</p>
          <p className="text-sm mt-2 text-gray-200">Blogs for upskilling</p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
