import React from "react";
import HeadindText from "../pages/Utils/HeadindText";

const successStories = [
  {
    title: "Paytm Generative AI Interview",
    description:
      "Received an exclusive interview call from Paytm's Generative AI team. This opportunity highlights proficiency in advanced artificial intelligence concepts, showcasing technical excellence, problem-solving skills, and a passion for innovation in AI.",
    emoji: "🚀",
    author: {
      name: "Krishna kaushik",
      college: "Chennai India",
      image: "https://i.ibb.co/SfGKgLM/image.png",
    },
  },
  {
    title: "Tech Mahindra Generative AI Offer",
    description:
      "Achieved a significant milestone by cracking the Tech Mahindra Generative AI interview. This full-time offer recognizes the ability to leverage AI for enterprise solutions, including automation, data modeling, and delivering impactful business insights.",
    emoji: "🏆",
    author: {
      name: "Anushka Gharage",
      college: "Maharashtra, India",
      image: "https://i.ibb.co/LpsF4pC/image.png",
    },
  },
  {
    title: "IIT Bombay AI Internship (6 Months Paid)",
    description:
      "Secured a prestigious 6-month paid internship in AI research at IIT Bombay. This role involved collaborating with world-class professors and researchers, contributing to innovative AI projects aimed at solving real-world challenges with cutting-edge technologies.",
    emoji: "🎓",
    author: {
      name: "Krishna Kaushik",
      college: "Chennai, India",
      image: "https://i.ibb.co/SfGKgLM/image.png",
    },
  },
  
];

const SuccessStories = () => {
  return (
    <div className="mt-16 w-[80vw] max-md:w-[98vw] mb-12">
      <h3 className="text-3xl font-bold text-center mb-8">
        <HeadindText Heading={`Success Stories`} />
      </h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-4">
        {successStories.map((story, index) => (
          <div
            key={index}
            className="relative group bg-white shadow-lg p-6 rounded-lg border-t-4 border-gray-700 transition-all duration-700 ease-out hover:shadow-2xl hover:scale-105 hover:bg-gradient-to-b from-gray-600 to-gray-700 cursor-pointer z-[1] hover:z-[10] mb-6"
          >
            <h4 className="text-xl font-semibold text-gray-800 mb-4 flex items-center transition-colors duration-500 group-hover:text-white">
              <span className="mr-2">{story.emoji}</span> {story.title}
            </h4>
            <p className="text-gray-600 transition-all duration-500 group-hover:text-gray-200">
              {story.description}
            </p>
            {/* Additional Section */}
            <div className="absolute w-full bottom-[-60px] left-0 hidden group-hover:block  p-4 bg-gray-700 rounded-lg shadow-inner transition-all duration-500 ease-out">
              <div className="flex items-center gap-4">
                <img
                  src={story.author.image}
                  alt={story.author.name}
                  className="w-12 h-12 rounded-full"
                />
                <div>
                  <h5 className="text-lg font-medium text-gray-200">{story.author.name}</h5>
                  <p className="text-sm text-gray-200">{story.author.college}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SuccessStories;
