import React, { useEffect, useRef, useState } from "react";
import { Courses, Projects } from "../particles/DataLists";
import CourseCard from "../molecules/CourseCard";
import ProjectCard from "../molecules/ProjectCard";
import Footer from "../organs/Footer";
import MemorizationCards from "./Carousels/MemorizationCards";
import trophy from "../../assets/trophy.png";
import EmailSubscribe from "./MailChamp/EmailSubscribe";
import "./home.css";
import ScrollTypingEffect from "./Helpers/ScrollTypingEffect";
import MilestoneTimeline from "./MilestoneTimeline";
import GroupClassRequest from "./Coaching/GroupClassRequest";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  cnnArchitectures,
  seqToSeq,
  reinforcementLearningModels,
  generativeModels,
  unsupervisedLearningModels,
  transferLearningModels,
  explainableAIModels,
  multimodalModels,
  audioGenerationModels,
  videoGenerationModels,
} from "./modelHistoryData";
import { useNavigate } from "react-router-dom";
import ProjectComp from "./ProjectComp";
import Pricing from "./Pricing/Pricing";
import MarkdownToSpeech from "../Projects/AudioGen/MarkdownToSpeech";
import Testimonials from "./Reviews/Testimonials";
import FaqSection from "./FAQs/FaqSection";
import PrivacyModal from "./Reviews/PrivacyModal";
import ProjectDemo from "./Reviews/ProjectDemo";
import { Gallery } from "./Gallery/Gallery";
import AcehubFeatures from "./AcehubFeatures";
import AudioChatbot from "./ModelComponent/AudioChatbot";
import Hero from "../Hero/Hero";
import Certification from "./Certifications/Certification";

import HeadindText from "./Utils/HeadindText";
import Mentor from "./Mentor/Mentor";
import Marquee from "../LandingPageComponents/Marquee";
import SuccessStories from "../LandingPageComponents/SuccessStories";
import SuccessTraining from "../LandingPageComponents/SuccessTraining";
import WhyRelevance from "../LandingPageComponents/WhyRelevance";
import CarouselCardCompo from "../LandingPageComponents/CarouselCardCompo";
import { discordInvite } from "../../config";

export default function Home({ signedin }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Do not auto switch the categories
    // const categories = ["Eng", "Data", "Sci", "Dev"];
    // let currentIndex = 0;
    // const switchCategory = () => {
    //   setCateg(categories[currentIndex]);
    //   currentIndex = (currentIndex + 1) % categories.length;
    // };
    // const intervalId = setInterval(switchCategory, 8000);
    // return () => {
    //   clearInterval(intervalId);
    // };
  }, []);

  function closeModal() {
    setModalOpen(false);
  }

  const emailSubscribeRef = useRef(null); // Create a ref

  const scrollToEmailSubscribe = () => {
    emailSubscribeRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const [emaildata, setEmailData] = useState("");

  return (
    <>
      <main
        className="pt-1 flex flex-col items-center"
        style={{ marginTop: "86px" }}
      >
        <PrivacyModal />

        {/* Hero section */}

        {!signedin ? (
          <Hero
            scrollToEmailSubscribe={scrollToEmailSubscribe}
            setEmailData={setEmailData}
          />
        ) : (
          <></>
        )}

        <CarouselCardCompo />
        {/* <AudioChatbot /> */}

        {!signedin ? (
          <>
            {" "}
            <div className=" flex flex-wrap items-center justify-center gap-3 text-center mb-0 mt-20">
              <ProjectDemo />
            </div>
            <Marquee />
            <SuccessStories />
            <SuccessTraining />
            <Testimonials id="testimonials" />
            <WhyRelevance />
            <AcehubFeatures />
            <div id="Certification">
              <Certification />
            </div>
            <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl mt-12">
              <HeadindText Heading={`Pricing`} />
            </h2>
            <Pricing id="pricing" />
            <div id="Faqs" className="mx-auto max-w-8xl sm:text-center mt-10">
              <FaqSection />
            </div>
            {/*DO NOT DELETE, FUTURE NEED COMPONENT*/}
            {/*<h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">*/}
            {/*  <HeadindText Heading={`Acehub Community`} />*/}
            {/*</h2>*/}
            {/*<div id="gallery">*/}
            {/*  <Gallery />*/}
            {/*</div>*/}
            {/*DO NOT DELETE, FUTURE NEED COMPONENT*/}
            {/*<h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">*/}
            {/*  <HeadindText Heading={`Our Mentors`} />*/}
            {/*</h2>*/}
            {/*<div id="Mentor">*/}
            {/*  <Mentor />*/}
            {/*</div>*/}
          </>
        ) : (
          <></>
        )}
        <GroupClassRequest
          isOpen={modalOpen}
          onClose={closeModal}
          course={selectedCourse}
        />
        <div ref={emailSubscribeRef} className="mt-0">
          <EmailSubscribe
            buttonText="Stay Informed"
            btnBackgroundColor="#152f37"
            btnColor="white"
            emaildata={emaildata}
          />
        </div>
      </main>
      <Footer DiscordLink={`${discordInvite}`} />
    </>
  );
}

{
  /* <div className="font-bold text-black text-4xl flex flex-wrap items-center justify-center gap-3 text-center mb-4 mt-10">
          <span
            style={{
              background:
                "linear-gradient(90deg, rgb(255, 166, 97), rgb(246, 75, 77), rgb(235, 123, 163))",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Our Models
          </span>
        </div>
        <div className="mx-7 md:mx-5">
          <div className="flex items-center justify-center mt-20">
            <h2 className="text-center">Audio Generation Models</h2>
          </div>
          <MilestoneTimeline milestones={audioGenerationModels} />

          <div className="flex items-center justify-center mt-20">
            <h2 className="text-center">Video Generation Models</h2>
          </div>
          <MilestoneTimeline milestones={videoGenerationModels} />

          <div className="flex items-center justify-center mt-20">
            <h2 className="text-center">CNN Architectures</h2>
          </div>
          <MilestoneTimeline milestones={cnnArchitectures} />

          <div className="flex items-center justify-center mt-20">

            <h2 className="text-center">Sequence to Sequence</h2>
          </div>
          <MilestoneTimeline milestones={seqToSeq} />

          <div className="flex items-center justify-center mt-20">
            <h2 className="text-center">Reinforcement Learning</h2>
          </div>
          <MilestoneTimeline milestones={reinforcementLearningModels} />

          <div className="flex items-center justify-center mt-20">
            <h2 className="text-center">Generative Models</h2>
          </div>
          <MilestoneTimeline milestones={generativeModels} />

          <div className="flex items-center justify-center mt-20">
            <h2 className="text-center">Unsupervised Learning Models</h2>
          </div>
          <MilestoneTimeline milestones={unsupervisedLearningModels} />

          <div className="flex items-center justify-center mt-20">
            <h2 className="text-center">Transfer Learning Models</h2>
          </div>
          <MilestoneTimeline milestones={transferLearningModels} />

          <div className="flex items-center justify-center mt-20">
            <h2 className="text-center">Explainable AI Models</h2>
          </div>
          <MilestoneTimeline milestones={explainableAIModels} />

          <div className="flex items-center justify-center mt-20">
            <h2 className="text-center">Multimodal Models</h2>
          </div>
          <MilestoneTimeline milestones={multimodalModels} />
        </div> */
}
{
  /* <div className="mt-40">
          <MemorizationCards />
        </div> */
}
