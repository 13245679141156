import React from 'react'

const HeadindText = ({Heading}) => {
  return (
    <div
        className="pb-2 "
        style={{
          background:
            "linear-gradient(90deg, rgb(255, 166, 97), rgb(246, 75, 77), rgb(235, 123, 163))",
          // background:"white",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        {Heading}
      </div>
  )
}

export default HeadindText