import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthorProfileStore from "../pages/AuthorProfile/store/AuthorProfileStore";

const UserMenu = ({ verifiedemail, handleLogOut }) => {
  const { profile, fetchProfileData, createOrUpdateProfileData } =
    AuthorProfileStore();

  const navigate = useNavigate();

  const [isProfileReady, setIsProfileReady] = useState(false);

  useEffect(() => {
    if (isProfileReady && profile?.data?.name) {
      navigate(`/profile/${profile.data.name}`);
    }
  }, [isProfileReady, profile, navigate]);

  const Profile_Data = async () => {
    await fetchProfileData(`${verifiedemail}`);
    setIsProfileReady(true);
  };
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={menuRef}>
      {/* Account icon to toggle menu */}
      <div className="flex items-center justify-center gap-4">
        <div className="cursor-pointer flex items-center" onClick={toggleMenu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 w-8 text-gray-600 hover:text-gray-800 transition"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 11c2.21 0 4-1.79 4-4S14.21 3 12 3 8 4.79 8 7s1.79 4 4 4zm0 2c-3.87 0-7 3.13-7 7h14c0-3.87-3.13-7-7-7z"
            />
          </svg>
        </div>

        <button
          className="flex justify-center items-center mx-2 px-2 py-2 border bg-[#5865F2] bg-opacity-90 border-gray-400 text-white font-semibold text-sm h-10 rounded-lg border-opacity-10 jiggle-button "
          onClick={() => window.open("https://discord.gg/y9pmAxp79v", "_blank")}
        >
          <div className="mr-2">Community </div>

          <div>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 640 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"></path>
            </svg>
          </div>
        </button>
        <a
          onClick={() =>
            window.open(
              "https://chat.whatsapp.com/CSz2eUZLkaU9CprMU2o9W6",
              "_blank"
            )
          }
          className="w-10 h-10 rounded-lg bg-green-500 p-2 shadow-lg transition-transform transform hover:scale-110 flex items-center justify-center  duration-300"
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
            alt="WhatsApp Logo"
            className="w-full h-full"
          />
        </a>
      </div>
      {/* Pop-up menu */}
      {showMenu && (
        <div onClick={toggleMenu} className="modal-overlay">
          <div
            onClick={(e) => e.stopPropagation()}
            className="absolute left-0 mt-2 w-48 bg-white rounded-lg shadow-lg p-2 modal-content"
          >
            <p style={{ fontSize: "12px", color: "grey" }}>
              Email: {verifiedemail}
            </p>
            <div className="flex flex-col items-start mt-2 space-y-2">
              <a
                onClick={() => navigate("/videos")}
                className="font-semibold text-gray-700 hover:bg-gray-100 px-4 py-2 rounded-lg transition duration-100 w-full flex items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-2"
                  viewBox="0 0 24 24"
                  fill="red"
                >
                  <circle cx="12" cy="12" r="10" fill="red" />
                  <path d="M10 8.64l6.8 3.36-6.8 3.36V8.64z" fill="white" />
                </svg>
                Videos
              </a>
              <a
                // onClick={() => Profile_Data()}
                onClick={() => navigate("/profile/neugence")}
                className="font-semibold text-gray-700 hover:bg-gray-100 px-4 py-2 rounded-lg transition duration-100 w-full flex items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 11c2.21 0 4-1.79 4-4S14.21 3 12 3 8 4.79 8 7s1.79 4 4 4zm0 2c-3.87 0-7 3.13-7 7h14c0-3.87-3.13-7-7-7z"
                  />
                </svg>
                My Profile
              </a>
              <a
                onClick={() => navigate("/workspace")}
                className="font-semibold text-gray-700 hover:bg-gray-100 px-4 py-2 rounded-lg transition duration-100 w-full flex items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-5 w-5 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                  />
                </svg>
                Workspace
              </a>
              <div
                className="font-semibold text-gray-700 hover:bg-gray-100 px-4 py-2 rounded-lg transition duration-100 w-full flex items-center cursor-pointer"
                onClick={handleLogOut}
              >
                Logout
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserMenu;
