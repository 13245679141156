import { BiBookBookmark } from "react-icons/bi";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { FaCartPlus, FaDiscord } from "react-icons/fa";
import { FiLock, FiPlayCircle } from "react-icons/fi";
import { FaFlag } from "react-icons/fa";
import ReactPlayer from "react-player";
import React, { useEffect, useState } from "react";

import { Projects } from "../../../Components/particles/DataLists";
import { useParams, useSearchParams } from "react-router-dom";
import AudioToAudioChatbotBenefits from "./AudioToAudioChatbotBenefits";
import Certification from "../Certifications/Certification";
import Testimonials from "../Reviews/Testimonials";
import AudioToAudioChatbotCurriculum from "./AudioToAudioChatbotCurriculum";
import MachineLearningProjects from "./MachineLearningProjects";
import CourseComponent from "./CourseComponent";
import Footer from "../../organs/Footer";
import ProjectCertification from "../Certifications/ProjectCertification";
import ModelBlogComponent from "./ModelBlogComponent";
import BlogComp from "../BlogComp";
import { discordInvite } from "../../../config";

export default function MarkdownToAudioProjectDesc({ signedin }) {
  // const [currVideoUrl, setCurrVideoUrl] = useState("https://blogs-images-and-videos.del1.vultrobjects.com/test1@gmail.com/cbedad86-bfb6-46fa-9440-2dd21a32c7ab/output.m3u8");
  const [currVideoUrl, setCurrVideoUrl] = useState(
    "https://www.youtube.com/watch?v=uew-IM2Ed7Y"
  );
  const [videoTitle, setVideoTitle] = useState("Introduction");
  const { project_id } = useParams();
  const [searchParams] = useSearchParams();
  // const { slugId } = useParams();
  const [data, setData] = useState(Projects[0]);

  useEffect(() => {
    // const slugId = searchParams.get("id");
    // const p = Projects.find((p) => p.id === slugId);
    const p = Projects.find((p) => p.id === project_id);
    setData(p || Projects[0]);
    setCurrVideoUrl(p.modules[0].chapters[0].video_url);
    setVideoTitle(p.modules[0].chapters[0].title);
  }, []);

  function sectionClicked(videoUrl, title, contentLocked) {
    if (contentLocked) {
      alert("Unlock with purchase, request on discord.");
    } else {
      setCurrVideoUrl(videoUrl);
      setVideoTitle(title);
    }
  }
  const [expandedSections, setExpandedSections] = useState({});
  const [visitedChapters, setVisitedChapters] = useState({});

  const toggleModule = (moduleIndex) => {
    setExpandedSections((prevSections) => ({
      ...prevSections,
      [moduleIndex]: !prevSections[moduleIndex],
    }));
  };

  const handleChapterClick = (moduleIndex, chapterIndex, chapter) => {
    if (chapter.locked) {
      alert("This chapter is locked.");
      return;
    }
    setVisitedChapters((prevVisited) => ({
      ...prevVisited,
      [`${moduleIndex}-${chapterIndex}`]: true,
    }));
    sectionClicked(chapter.video_url, chapter.title, chapter.locked);
  };
  useEffect(() => {
    setExpandedSections({ 0: true });
    setVisitedChapters({ "0-0": true });
  }, []);

  return (
    <main className="pt-24 w-[100vw] relative">
      {!signedin && <div className="w-full px-12 py-20 bg-gradient-to-br from-color3 to-color7 -mt-2">
       <div className="w-full mx-auto flex flex-col gap-6 md:flex-row justify-between items-start">
          <div className="flex flex-col gap-1">
            <h1 className="text-2xl font-semibold text-white">{data.title}</h1>
            <p className="opacity-60 text-sm text-white">{data.description}</p>
            <div className="flex gap-4 mt-3">
              <span className="flex gap-1 items-center justify-center text-gray-300">
                <BiBookBookmark className="text-gray-400 text-lg" />{" "}
                {data.stats.chapters} lessons
              </span>
              <span className="pl-4 border-l border-gray-500 flex gap-1 items-center justify-center text-gray-300">
                {Array.from({ length: Math.floor(data.rating) }).map(
                  (_, index) => (
                    <AiFillStar
                      key={`full-${index}`}
                      className="text-color2 text-lg"
                    />
                  )
                )}
                {data.rating % 1 !== 0 && (
                  <div
                    className="relative text-lg"
                    style={{
                      width: "1em",
                      height: "1em",
                      display: "inline-block",
                      lineHeight: "0",
                      verticalAlign: "middle",
                    }}
                  >
                    <AiFillStar
                      className="text-color2 text-lg"
                      style={{
                        position: "absolute",
                        clipPath: `inset(0 ${
                          (1 - (data.rating % 1)) * 100
                        }% 0 0)`, // Dynamic fill based on decimal
                        top: 0,
                        left: 0,
                      }}
                    />
                    <AiOutlineStar
                      className="opacity-50 text-lg"
                      style={{
                        position: "absolute",
                        clipPath: `inset(0 0 0 ${(data.rating % 1) * 100}%)`, // Complements the filled part
                        top: 0,
                        left: 0,
                      }}
                    />
                  </div>
                )}
                {Array.from({ length: 5 - Math.ceil(data.rating) }).map(
                  (_, index) => (
                    <AiOutlineStar
                      key={`empty-${index}`}
                      className="opacity-50 text-lg"
                    />
                  )
                )}
                {data.rating.toFixed(1)} ({data.ratings} ratings)
              </span>
            </div>
          </div>
          <div className="flex gap-3">
            <button
              className="px-5 py-2 border bg-color2 border-gray-400 text-white font-semibold h-auto rounded-lg border-opacity-10"
              onClick={() =>
                window.open(`${discordInvite()}`, "_blank")
              }
            >
              Enroll in Project
            </button>
            {/*<button className="px-3 py-2 border bg-color6 bg-opacity-10 border-gray-400 text-white font-semibold h-auto rounded-lg border-opacity-10">*/}
            {/*  <FaCartPlus />*/}
            {/*</button>*/}
            <button
              className="px-3 py-2 border bg-[#5865F2] bg-opacity-90 border-gray-400 text-white font-semibold h-auto rounded-lg border-opacity-10"
              onClick={() =>
                window.open(`${discordInvite()}`, "_blank")
              }
            >
              <FaDiscord />
            </button>
          </div>
        </div>
      </div>}
      <div className="max-w-[1400px] overflow-y-auto mx-auto bg-white border border-gray-200 rounded-t-3xl md:h-[72vh] min-h-[72vh] -mt-6 pb-24 md:pb-0 px-8 py-8 lg:grid lg:grid-cols-2 flex flex-col-reverse gap-12">
        {signedin ? (
          <BlogComp
          useremail={"published@neugence.ai"}
          
        />
        ) : (
          <div className="w-full bg-white overflow-y-auto mx-auto max-h-[700px] pr-4">
            {data.modules.map((module, moduleIndex) => (
              <div key={module.title} className="mb-2">
                <div
                  onClick={() => toggleModule(moduleIndex)}
                  className={`p-4 cursor-pointer rounded-lg text-md font-semibold transition-colors duration-300 ${
                    expandedSections[moduleIndex]
                      ? "bg-gray-800 text-white"
                      : "bg-gray-200 text-black"
                  }`}
                >
                  {moduleIndex + 1}. {module.title}
                </div>
                {expandedSections[moduleIndex] && (
                  <div className="pl-4 mt-2">
                    {module.chapters.map((chapter, chapterIndex) => (
                      <div
                        key={chapter.title}
                        onClick={() =>
                          handleChapterClick(moduleIndex, chapterIndex, chapter)
                          
                        }
                        className={`flex items-center justify-between p-4 py-2 rounded-lg transition-colors duration-300 mb-[2px]  ${
                          visitedChapters[`${moduleIndex}-${chapterIndex}`]
                            ? "bg-indigo-50 bg-opacity-100 text-black hover:bg-indigo-100 font-semibold"
                            : "bg-white text-black hover:bg-indigo-100 font-semibold cursor-pointer"
                        }`}
                      > 
                        <div className="flex items-center space-x-3">
                        {moduleIndex+1}.{chapterIndex+1 }
                          {chapterIndex === module.chapters.length - 1 ? (
                            <FaFlag className="text-color2 ml-2" />
                          ) : (
                            <FiPlayCircle className="ml-2" />
                          )}
                          <span className="text-sm font-medium ml-2">
                            {chapter.title}
                          </span>
                        </div>
                        <div className="flex items-center space-x-3">
                          <span className="text-xs text-gray-500">
                            {chapter.video_len}
                          </span>
                          {chapter.locked && (
                            <FiLock className="text-gray-500" />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        

        {/*Video Section Here*/}
        {!signedin && <div className="w-full flex flex-col gap-3">
          <h6 className="text-md font-normal mb-1">{videoTitle}</h6>
          <ReactPlayer
            url={currVideoUrl}
            controls
            width="100%"
            height="320px" // Adjust height as needed
            className="rounded-lg shadow-lg border border-gray-700"
            config={{
              file: {
                forceHLS: true, // Ensures that HLS is used
                hlsOptions: {
                  debug: false, // Disable debug mode unless needed
                },
              },
            }}
          />

          <div className="flex mt-5 flex-col gap-1 ml-0">
            <h4 className="text-xl font-medium underline">Description</h4>
            <p className="text-sm">
             
              Get ready to upskill in Ai.
            </p>
          </div>
        </div>}
      </div>

      {!signedin?<><AudioToAudioChatbotBenefits />
      <ProjectCertification />
      <AudioToAudioChatbotCurriculum />
      <Testimonials />
      <MachineLearningProjects />
      <CourseComponent />
      <Footer /></>:<></>}
    </main>
  );
}
