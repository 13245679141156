export const TestimonialTextData = {
  feedBacks: [
    {
      text: `Hi, I am Baibhav Kundu, a second-year student at NSUT, Delhi and an AI-ML intern at Neugence Technologies
On my first day, I was given the task to create a text to speech model. It was really overwhelming for me as till then, I was not even experienced with deep learning. But through my efforts and guidance from sir, I found my way through all the hardships. I learned about prompt engineering, about the process of fine-tuning a transformer like T5, training a model incrementally which helped me in hackathons as well. Also, I learnt how to network with colleagues and got to know how things work in a company and many more.
Now, I am confident with my skillset and my ability to handle complex tasks. I am able to work comfortably with NLP and the credit goes to Acehub.`,
      person: "Bhaibhav",
      location: "NSUT Delhi, India",
      designation: "AI Engineer",
      imageUrl: "https://i.ibb.co/V94Mr3x/image.png",
      github: "https://github.com/BaibhavKundu2005",
      linkedin: "https://www.linkedin.com/in/baibhavkundu/",
    },
    {
      text: "I have a wonderful experience working as an AI-ML intern at NEUGENCE. The collaborative environment and the guidance have helped me grow both technically and professionally. Working on real-world projects has given me the opportunity to apply my knowledge and learn new skills in machine learning and AI.",
      person: "Putul Singh",
      location: "NIT Hamirpur, India",
      designation: "AI Engineer",
      imageUrl: "https://i.ibb.co/Z2bknXG/image.png",
      github: "https://www.linkedin.com/in/putul-singh-80036b25a/",
      linkedin: "https://www.linkedin.com/in/putul-singh-80036b25a/",
    },
    {
      text: "AceHub gave me the opportunity to dive deep into AI while also building my full-stack skills. The feedback loop from industry experts helped me fine-tune my approach to projects and accelerated my learning curve.",
      person: "Yash Raj Hans",
      location: "MAIT Delhi, India",
      designation: "Full Stack & AI Ops",
      imageUrl: "https://i.ibb.co/r4ZY585/image.png",
      github: "https://github.com/YASH-RAJ-HANS",
      linkedin: "https://www.linkedin.com/in/yash-raj-hans-7827a3224/",
    },
    {
      text: "Since joining Neugence as an AI-ML intern, I’ve gained hands-on experience with various technologies, from building custom datasets to training state-of-the-art machine learning models. It has also helped me dive deep into topics and conducting research which significantly expanded my knowledge. Beyond technical skills, I’ve also improved on communicating more, learning to engage more effectively to better understand problems and deliver the required results. The team's mentorship and the supportive environment has helped me learn from my mistakes, and have greatly accelerated both my technical and behavioral growth.",
      person: "Anushka Gharage",
      location: "Maharashtra , India",
      designation: "Data Scientist",
      imageUrl: "https://i.ibb.co/LpsF4pC/image.png",

      linkedin: "https://www.linkedin.com/in/anushka-gharage/",
      github: "https://github.com/AnushkaGharage",
    },
    {
      text: "Hi, I’m Krishna Kaushik, an intern at Neugence Company. Since joining, I’ve been assigned industrial-level AI projects, and Neugence has been instrumental in my development. I’ve received continuous guidance and constructive feedback, which have helped me refine my work and achieve better results. I've also gained valuable skills in project redesign and time management, fostering a strong sense of discipline. This experience has been a continuous journey of learning, and I’m grateful for the support I’ve received in building my professional skills",
      person: "Krishna Kaushik",
      location: "Chennai, India",
      designation: "AI Engineer",
      imageUrl: "https://i.ibb.co/SfGKgLM/image.png",

      github: "https://github.com/krishnakaushik195",
      linkedin: "https://www.linkedin.com/in/krishna-kaushik/",
    },
    {
      text: "From starting this internship, ive learned about team co-ordination, teamwork and adapted and working with a good team showed how decisions take place. its shown me the importance of working in an energetic environment and hitting real project goals. Also i started exploring YOLO and PyTorch which really boosted my skills, enhanced my proficiency in the AI field.",
      person: "Rahul Marban",
      location: "India",
      designation: "AI & Cloud Engineer",
      imageUrl: "https://i.ibb.co/R3PsTmv/image.png",
      linkedin: "https://www.linkedin.com/in/rahul-marban/",
      github: "https://www.linkedin.com/in/rahul-marban/",
    },
  ],
};
export const ReviewTextData = {
  firstText: "Our Customers Reviews",
  cards: [
    {
      text: "You did a fabulous job. There were no hitches. Thank you again for all your work planning this trip.",
      person: "Mike taylor",
      rating: "4",
    },
    {
      text: "You did a fabulous job. There were no hitches. Thank you again for all your work planning this trip.",
      person: "Mike taylor",
      rating: "4",
    },
    {
      text: "You did a fabulous job. There were no hitches. Thank you again for all your work planning this trip.",
      person: "Mike taylor",
      rating: "4",
    },
    {
      text: "You did a fabulous job. There were no hitches. Thank you again for all your work planning this trip.",
      person: "Mike taylor",
      rating: "4",
    },
    {
      text: "You did a fabulous job. There were no hitches. Thank you again for all your work planning this trip.",
      person: "Mike taylor",
      rating: "4",
    },
    {
      text: "You did a fabulous job. There were no hitches. Thank you again for all your work planning this trip.",
      person: "Mike taylor",
      rating: "4",
    },
  ],
};
