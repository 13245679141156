import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { mentorsData } from "../../particles/DataLists";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import HeadindText from "../Utils/HeadindText";

const Mentor = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMentorsPage = location.pathname === "/mentors";

  const handleViewMore = (name) => {
    const formattedName = name.replace(/\s+/g, "_");
    navigate(`/mentors#${formattedName}`);
  };

  return (
    <div className="container mx-auto p-6 grid gap-8 w-full">
      {isMentorsPage ? (
        <div className="w-full mx-auto mt-[90px] min-h-screen flex flex-wrap justify-around gap-8">
          <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl w-full text-center">
            <HeadindText Heading={`Our Mentors`} />
          </h2>
          {mentorsData.map((mentor, index) => (
            <div
              key={mentor.person}
              id={`${mentor.person.replace(/\s+/g, "_")}`}
              className={`bg-gradient-to-tr from-color3 to-[#212f4c] rounded-3xl shadow-lg flex flex-col md:flex-row ${
                index % 2 === 0 ? "md:flex-row-reverse" : ""
              } transition-transform duration-500 hover:scale-105 cursor-pointer w-full md:w-10/12`}
            >
              <img
                src={mentor.imageUrl}
                alt={mentor.person}
                className="w-full md:w-1/4 h-60 md:h-auto object-cover transition-transform duration-300 ease-in-out rounded-t-3xl md:rounded-l-3xl md:rounded-tr-none"
              />
              <div className="p-6 md:px-10 flex flex-col justify-center text-white w-full">
                <h2 className="text-2xl font-semibold">{mentor.person}</h2>
                <p className="text-sm font-light text-gray-300 mb-2">
                  {mentor.designation} - {mentor.location}
                </p>
                <p className="text-md leading-relaxed mt-2">{mentor.text}</p>
                {mentor.bulletPoints && (
                  <ul className="list-disc ml-5 mt-2 space-y-1 text-sm text-gray-200">
                    {mentor.bulletPoints.map((point, i) => (
                      <li key={i}>{point}</li>
                    ))}
                  </ul>
                )}
                <div className="flex gap-4 mt-4">
                  {mentor.linkedin && (
                    <a
                      href={mentor.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-400 hover:text-blue-500 transition-colors"
                    >
                      <FaLinkedin size={20} />
                    </a>
                  )}
                  {mentor.github && (
                    <a
                      href={mentor.github}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-300 hover:text-gray-400 transition-colors"
                    >
                      <FaGithub size={20} />
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="p-6 flex flex-wrap gap-8 items-center justify-around w-full">
          {mentorsData.slice(0, 4).map((mentor) => (
            <div
              key={mentor.person}
              className="relative bg-gradient-to-br from-[#1b2a4b] to-[#212f4c] rounded-lg shadow-xl flex flex-col md:flex-row transition-transform hover:scale-105 duration-300 w-full md:w-[45%] h-auto md:h-[300px]"
            >
              <img
                src={mentor.imageUrl}
                alt={mentor.person}
                className="w-full md:w-1/3 h-40 md:h-full object-cover rounded-t-lg md:rounded-l-lg"
              />
              <div className="p-6 flex flex-col justify-between text-white w-full md:w-2/3 bg-gradient-to-br from-[#263859] to-[#1f2d40] rounded-b-lg md:rounded-r-lg">
                <div>
                  <h2 className="text-lg font-bold">{mentor.person}</h2>
                  <p className="text-sm text-gray-400">
                    {mentor.designation} - {mentor.location}
                  </p>
                </div>
                {mentor.bulletPoints && (
                  <ul className="list-disc ml-5 mt-2 space-y-1 text-sm text-gray-200">
                    {mentor.bulletPoints.map((point, i) => (
                      <li key={i}>{point}</li>
                    ))}
                  </ul>
                )}
                <div className="mt-4 flex space-x-4">
                  {mentor.github && (
                    <a
                      href={mentor.github}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-300 hover:text-white"
                    >
                      <FaGithub size={20} />
                    </a>
                  )}
                  {mentor.linkedin && (
                    <a
                      href={mentor.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-400 hover:text-blue-500"
                    >
                      <FaLinkedin size={20} />
                    </a>
                  )}
                  <button
                    onClick={() => handleViewMore(mentor.person)}
                    className="text-gray-300 hover:text-white"
                  >
                    <DoubleArrowIcon size={20} />
                  </button>
                </div>
              </div>
            </div>
          ))}
          <button
            className="bg-indigo-500 text-white px-4 py-2 rounded-lg transition-transform hover:scale-105 hover:bg-indigo-600"
            onClick={() => navigate("/mentors")}
          >
            View More
          </button>
        </div>
      )}
    </div>
  );
};

export default Mentor;
