import React, { useEffect, useState } from "react";
import { Courses, Projects } from "../particles/DataLists";
import CourseCard from "../molecules/CourseCard";
import ProjectCard from "../molecules/ProjectCard";
import trophy from "../../assets/trophy.png";
import ScrollTypingEffect from "../../Components/pages/Helpers/ScrollTypingEffect";
import { useNavigate } from "react-router-dom";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";

const CarouselCardCompo = () => {
  const navigate = useNavigate();

  const calculateItemsPerPage = () => {
    if (window.innerWidth >= 1440) return 4;
    if (window.innerWidth >= 1024) return 3;
    if (window.innerWidth >= 768) return 2;
    return 1;
  };

  const [itemsPerPage, setItemsPerPage] = useState(calculateItemsPerPage());
  const [dataChunks, setDataChunks] = useState({
    Eng: [],
    Data: [],
  });
  const [currentIndexes, setCurrentIndexes] = useState({
    Eng: 0,
    Data: 0,
  });
  const [categ, setCateg] = useState("Eng");

  useEffect(() => {
    const handleResize = () => setItemsPerPage(calculateItemsPerPage());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const chunkData = (array) => {
      const chunks = [];
      for (let i = 0; i < array.length; i += itemsPerPage) {
        chunks.push(array.slice(i, i + itemsPerPage));
      }
      return chunks;
    };

    setDataChunks({
      Eng: chunkData(Projects),
      Data: chunkData(Courses),
    });
  }, [itemsPerPage]);

  const handleNext = () => {
    setCurrentIndexes((prev) => ({
      ...prev,
      [categ]: Math.min(prev[categ] + 1, dataChunks[categ].length - 1),
    }));
  };

  const handlePrev = () => {
    setCurrentIndexes((prev) => ({
      ...prev,
      [categ]: Math.max(prev[categ] - 1, 0),
    }));
  };

  const onClickSelfStudy = (courseId) => {
    navigate(`/course/${courseId}`);
  };

  const onClickSelfStudyProject = (courseId) => {
    window.scrollTo(0, 0);
    navigate(`/projects/${courseId}`);
  };

  const onClickGroupClass = (course) => {
    console.log("Group class clicked", course);
  };

  return (
    <div className="bg-gradient-to-tr  from-color3 to-[#212f4c] rounded-b-3xl w-full flex m-auto overflow-hidden mt-[-3px] items-start justify-center relative">
      
      <div className="absolute top-10 left-5 w-60 h-60 bg-white opacity-10 rounded-full filter blur-2xl "></div>
      <div className="absolute top-2 right-10 w-64 h-64 bg-white opacity-20 rounded-full filter blur-3xl "></div>
      <div className="absolute top-40 left-[40vw] w-64 h-64 bg-white opacity-20 rounded-full filter blur-3xl "></div>
      <div className="flex flex-col gap-10 h-fit mt-6 items-center justify-start mb-12 pb-16 pt-12 md:mx-16 rounded-3xl ">
        {/* Heading */}
        <div
          className="font-bold text-white flex-col xl:flex-row text-4xl flex flex-wrap items-center justify-center gap-3 text-center mb-0"
          style={{ marginTop: "-20px" }}
        >
          <img
            alt="trophy"
            src={trophy}
            className="w-[40px] h-[40px] opacity-90 mt-[-10px]"
          />
          <div
            className="font-bold text-white text-4xl flex flex-wrap items-center justify-center gap-3 text-center mb-0"
            style={{
              background:
                "linear-gradient(90deg, rgb(255, 166, 97), rgb(246, 75, 77), rgb(235, 123, 163))",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Projects & Courses.
          </div>
        </div>

        {/* Typing Effect */}
        <div style={{ color: "white", display: "block", marginTop: "-30px" }}>
          <ScrollTypingEffect
            targetText={"AI will not take jobs. People knowing AI will."}
            fontSize={"1em"}
          />
        </div>

        {/* Category Tabs */}
        <div className="flex items-center flex-col gap-2 relative">
          <div className="flex gap-4 items-center justify-center mb-10">
            <div className="bg-gray-200 h-[1px] xl:w-[200px] w-[100px] flex opacity-20" />
            <div className="sticky select-none shadow-md z-10 flex gap-3 bg-[#D9D9D9] max-w-[90vw] rounded-full p-[3px] overflow-auto">
              <button
                onClick={() => setCateg("Eng")}
                className={`font-semibold select-none rounded-full text-lg px-5 py-2 ${
                  categ === "Eng" ? "bg-white" : ""
                }`}
              >
                AI Projects
              </button>
              <button
                onClick={() => setCateg("Data")}
                className={`font-semibold rounded-full text-lg px-5 py-2 ${
                  categ === "Data" ? "bg-white" : ""
                }`}
              >
                Courses
              </button>
            </div>
            <div className="bg-gray-200 h-[1px] xl:w-[200px] w-[100px] flex opacity-20" />
          </div>

          {/* Card Container */}
          <div className="relative w-full overflow-hidden">
            <div
              className="flex transition-transform duration-300 ease-in-out"
              style={{
                transform: `translateX(-${currentIndexes[categ] * 100}%)`,
              }}
            >
              {dataChunks[categ].map((chunkData, i) => (
                <div
                  key={i}
                  className="flex-shrink-0 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-6 p-4 cursor-pointer max-md:pl-12"
                >
                  {chunkData.map((subItem) =>
                    categ === "Data" ? (
                      <CourseCard
                        key={subItem.courseId}
                        course={subItem}
                        onClickSelfStudy={onClickSelfStudy}
                        onClickGroupClass={onClickGroupClass}
                        className="ml-6"
                      />
                    ) : (
                      <ProjectCard
                        key={subItem.courseId}
                        course={subItem}
                        onClickSelfStudyProject={onClickSelfStudyProject}
                        onClickGroupClass={onClickGroupClass}
                      />
                    )
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-end items-center max-md:justify-center mt-4 w-10/12">
            <button
              onClick={handlePrev}
              disabled={currentIndexes[categ] === 0}
              className={`border-4 p-2 rounded-lg mx-2 px-4 py-3 transition-all duration-300 ${
                currentIndexes[categ] === 0
                  ? "text-gray-400 border-gray-400 cursor-not-allowed"
                  : "text-white"
              }`}
            >
              ◀
            </button>
            <button
              onClick={handleNext}
              disabled={currentIndexes[categ] >= dataChunks[categ].length - 1}
              className={`border-4 p-2 rounded-lg mx-2 px-4 py-3 transition-all duration-300 ${
                currentIndexes[categ] >= dataChunks[categ].length - 1
                  ? "text-gray-400 border-gray-400 cursor-not-allowed"
                  : "text-white"
              }`}
            >
              ▶
            </button>
          </div>
          <div className="text-center">
            <a
              onClick={() => {
                categ === "Data" ? navigate(`/course`) : navigate(`/projects`);
              }}
              className="pb-2 text-base font-bold leading-7 text-white border-2 rounded-md border-white hover:border-white  hover:scale-105 transition-all duration-500 font-pj mt-4 cursor-pointer py-2 px-4 flex items-center gap-4"
            >
              {categ === "Data" ? `All Courses ` : ` All Projects`}{" "}
              <ArrowCircleRightOutlinedIcon size={20} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselCardCompo;
